import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Trykkbølgeterapi</h1>
      <p>
        Kiropraktor Akutten Stavanger/Sandnes tilbyr trykkbølgebehandling. Trykkbølgebehandling er samme teknologi som
        benyttes til å knuse blant annet nyrestein.
      </p>

      <p>
        Trykkbølgeapparat sender en rekke små, høyfrekvente slag mot huden som brer seg inn i vevet. Dette setter i gang
        en rekke fysiologiske reaksjoner i vevet som stimulerer kroppens egne reperasjonsprosesser.
      </p>

      <p>
        Kiropraktor Akutten Stavanger/Sandnes har trykkbølgeapparat fra den nyeste serien til Storz, verdens ledene
        produsent av trykkbølgeapparat.
      </p>
    </Layout>
  )
}

export default Page
